import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import Share from "../components/share"
import ContactForm from "../components/contactForm"
import styled from "@emotion/styled"
import { css, Global } from "@emotion/react"

export default ({ data }) => {
  const post = data.post

  return (
    <Layout pathEn="/en/">
      <SEO
        title={post.frontmatter.metaTitle}
        description={post.frontmatter.metaDescription}
      />
      <Helmet>
        <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Vidéos & Émissions TV",
            "item": "https://www.docteurphilipperoure.com/videos/"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "${post.frontmatter.title}",
            "item": "https://www.docteurphilipperoure.com${post.fields.slug}"
          }]
      }
    `}</script>
        <meta
          property="og:image"
          content={`https://www.docteurphilipperoure.com${post.frontmatter.image.childImageSharp.fluid.src}`}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="813" />
        <meta property="og:image:height" content="464" />
        <meta
          property="og:url"
          content={`https://www.docteurphilipperoure.com${post.fields.slug}`}
        />
        <meta property="og:title" content={post.frontmatter.metaTitle} />
        <meta
          property="og:description"
          content={post.frontmatter.metaDescription}
        />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="1086796511711258" />
      </Helmet>
      <Global />

      <WrapperText>
        <Breadcrumb>
          <Link to="/">Accueil</Link> /{" "}
          <Link to="/videos/">Vidéos & Émissions TV</Link>
        </Breadcrumb>
        <H1 dangerouslySetInnerHTML={{ __html: post.frontmatter.title }} />

        <div dangerouslySetInnerHTML={{ __html: post.html }} css={css``} />

        <Share slug={post.fields.slug} title={post.frontmatter.title} />
      </WrapperText>

      <ContactForm
        about={post.frontmatter.about}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        tags
        metaTitle
        metaDescription
        about
        image {
          childImageSharp {
            fluid(maxWidth: 813) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      fields {
        slug
      }
      html
    }
  }
`

const Breadcrumb = styled.div`
  text-align: center;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1em;
  a {
    color: #4f5767;
    text-decoration: none;
  }
  @media (max-width: 678px) {
    font-size: 0.87em;
    margin-bottom: 10px;
  }
`

const H1 = styled.h1`
  color: rgba(44, 44, 44, 0.96);
  font-family: Raleway, "Times new roman", serif;
  @media (max-width: 678px) {
    font-size: 2.1em;
    line-height: 1.1em;
  }
`

const WrapperText = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 0.5em auto 0.5em;
  padding: 1.5em 1.5em 1em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    padding: 1em 0.7em 1em;
  }
`
